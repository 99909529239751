import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import React, {lazy, Suspense} from "react";
import './App.scss';

function lazyWithPreload(factory) {
    const Component = lazy(factory);
    Component.preload = factory;
    return Component;
}

const ComponentToPreload = lazyWithPreload(() => import('./core/translationConfigs/TranslationLayout.component'));


function App() {
    ComponentToPreload.preload();
    return (
        <Router>
            <Suspense fallback={<></>}>
                <Switch>
                    <Route path='/' component={ComponentToPreload}/>
                </Switch>
            </Suspense>
        </Router>

    )
}

export default App;


